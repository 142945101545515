import { create } from 'zustand';

interface GlobalMessageStore {
    message: React.ReactNode | undefined;
    height: number;
    setMessage: (message: React.ReactNode | undefined) => void;
    setHeight: (height: number | undefined) => void;
}

export const useGlobalMessageStore = create<GlobalMessageStore>((set, get) => ({
    message:
        'On Thursday, January 9, 2025, stock and options markets will be closed in observance of the National Day of Mourning for President Jimmy Carter.  Thursday, January 9, 2025, will still be considered a settlement day for bank or brokerage transactions.',
    height: 0,
    setMessage: (message: React.ReactNode | undefined) => set((s) => ({ ...s, message })),
    setHeight: (height: number | undefined) => set((s) => ({ ...s, height: height || 0 }))
}));
